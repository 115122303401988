import { isValidWalmartProductId } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const WalmartProductContent: SDEContent = {
  projectType: 'async_walmart_product',
  projectCategory: 'walmart',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/walmart/product`;
  },
  inputKey: 'product_id',
  inputsKey: 'product_ids',
  validatorFunction: isValidWalmartProductId,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Product',
      listLiteralInputPlaceholder: 'Enter one product id per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input product IDs",
      inputSectionDescription: "Enter product IDs in the input field or upload CSV with your product IDs to begin scraping Walmart.",
    },
    projectContent: {
      title: 'Walmart product',
      description: 'Extract detailed product information from Walmart product pages with the product ID.',
      icon: 'walmart',
      inputTitle: 'Product id',
    },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input product ID',
      inputSectionDescription: 'Enter product IDs to begin scraping Walmart.',
    },
    projectContent: {
      title: 'Walmart product',
      description: 'Extract detailed product information from Walmart product pages with the product ID.',
      icon: 'walmart',
    },
    enterInputsPlaceholder: '3PLKTNYTF57C',
    enterInputsMessage: 'Enter product ID for scraping',
    codeViewPlaceHolder: 'Please provide a valid product id',
    dropdownTitle: 'Walmart Product'
  }
};
