import { useEffect } from "react";
import { useFetcher } from "react-router-dom";

import Button from "components/Button";
import Card from "components/Card";
import ExtLink from "components/ExtLink";
import Spinner from "components/Spinner";


interface IChangelogEntry {
  summary: string;
  updatedAt: string;
  readMoreLink: string;
  showDivider?: boolean;
}

function ChangelogEntry(
  {
    summary,
    updatedAt,
    readMoreLink,
    showDivider = false
  }: IChangelogEntry
) {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-1.5">
        <div className="text-sm text-neutral-900">
          { summary }
        </div>
        <div className="flex flex-row justify-between text-xs text-neutral-700">
          <div>{ updatedAt }</div>
          <ExtLink href={ readMoreLink } className="underline hover:text-neutral-900">Read more</ExtLink>
        </div>
      </div>
      { showDivider && (
        <div className="border-t-[1px] border-neutral-50 w-full" />
      ) }
    </div>
  );
}

export default function WhatsNewCard() {
  const changelogFetcher = useFetcher<{ viewAllLink: string, updates: IChangelogEntry[] }>();

  useEffect(() => {
    if ((changelogFetcher.state === "idle") && (changelogFetcher.data === undefined)) {
      changelogFetcher.load("/dashboard-data/changelog");
    }
  });


  return (
    <Card
      title="What's new?"
      extra={
        <Button
          text="View all"
          size="SM"
          href={ changelogFetcher.data?.viewAllLink }
          className="button button-text-primary text-xs"
        />
      }
    >
      { changelogFetcher.state === "loading" && (
        <Spinner/>
      ) }
      { changelogFetcher.state === "idle" && (
        <div className="flex flex-col gap-y-4">
          { changelogFetcher.data?.updates?.map((entry, idx, allEntries) => (
            <ChangelogEntry key={ idx } { ...entry } showDivider={ idx < allEntries.length - 1 } />
          )) }
        </div>
      ) }
    </Card>
  );
};
