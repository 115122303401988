import _ from "lodash";


export const formDataToStructuredObject = (formData: FormData): any => {
  const jsonObj = {};
  formData.forEach((value, key) => {
    // TODO formData can contain the same key multiple times, that means the value is an array... should also handle that case here
    _.set(jsonObj, key, value);
  });
  return jsonObj;
};
