import { createContext, ReactNode, useCallback, useContext, useState } from "react";

import { useFeatureSwitch } from "components/FeatureSwitch";

import { getCookie, setCookie } from "utils/cookieUtils";


type NewDashboardDesignSetting = "yes" | "no" | "never";

const cookieName = "saTryNewDashboardDesign";

type NewDashboardDesignContextType = {
  newDashboardDesignSetting: NewDashboardDesignSetting | undefined;
  updateNewDashboardDesignSetting: (newValue: NewDashboardDesignSetting) => void;
};

let NewDashboardDesignContext = createContext<NewDashboardDesignContextType>(null!);

export default function NewDashboardDesignProvider({ children }: { children?: ReactNode }) {
  const [ newDashboardDesignSetting, setNewDashboardDesignSetting ] = useState<NewDashboardDesignSetting>(getCookie(cookieName) as NewDashboardDesignSetting);
  const newDesignPilotEnabled = useFeatureSwitch("REACT_APP_NEW_DASHBOARD_DESIGN_PILOT_USERS");

  const updateNewDashboardDesignSetting = useCallback((newValue: NewDashboardDesignSetting) => {
    let cookieOptions: Cookies.CookieAttributes | undefined;
    if (newValue === "no") {
      // store negative answers for 1 week only
      cookieOptions = { expires: 7 };
    }

    setCookie(cookieName, newValue, cookieOptions);

    setNewDashboardDesignSetting(newValue);
  }, []);

  return (
    <NewDashboardDesignContext.Provider value={ {
      newDashboardDesignSetting: newDesignPilotEnabled ? newDashboardDesignSetting : "no",
      updateNewDashboardDesignSetting: newDesignPilotEnabled ? () => {
      } : updateNewDashboardDesignSetting
    } }
    >
      { children }
    </NewDashboardDesignContext.Provider>
  );
};

export function useNewDashboardDesign() {
  return useContext(NewDashboardDesignContext);
}
