import { useCallback } from "react";
import { RiFileCopyLine } from "@remixicon/react";

import Button from "components/Button";
import Card from "components/Card";
import DecoratedInputField from "components/InputFieldComponents/DecoratedInputField";
import Tag from "components/Tag";
import { Tooltip } from "components/Tooltip";

import { useCopyToClipboard } from "hooks/useCopyToClipboard";

import Messages from "misc/Messages";

import { useUser } from "routes/dataroutes/UserData";


export default function ApiKeyCard() {
  const user = useUser();
  const [ copied, copyToClipboard ] = useCopyToClipboard();

  const copyApiKeyToClipboard = useCallback(() => {
    copyToClipboard(user?.apiKey);
  }, [ copyToClipboard, user?.apiKey ]);

  return (
    <Card
      title={ <Tooltip content={ Messages.apiKeyCardTooltipMessage } className="self-start">API key</Tooltip> }
      extra={
        <Button
          text="Manage"
          size="SM"
          className="button button-text-primary text-xs"
          href="/api-keys"
        />
      }
    >
      <DecoratedInputField
        type="text"
        readOnly
        value={ user?.apiKey }
        inputPaddingForTrailingElement="!pr-8"
        TrailingElement={ <div className="flex items-center gap-x-1">
          { copied && <Tag label="Copied" subtle /> }
          <RiFileCopyLine className="w-4 h-4" onClick={ copyApiKeyToClipboard } />
        </div> }
        className="truncate"
        onClick={ copyApiKeyToClipboard }
      />
    </Card>
  );
};
