import { cx } from "utils";


type TagSize = "SM" | "MD";

const tagSizes: Record<TagSize, string> = {
  SM: "tag-sm",
  MD: "tag-md"
};

type TagTheme = "neutral" | "info" | "success" | "warning" | "error" | "secondary";

const tagThemes: Record<TagTheme, string> = {
  neutral: "tag-neutral",
  info: "tag-info",
  success: "tag-success",
  warning: "tag-warning",
  error: "tag-error",
  secondary: "tag-secondary"
};


export default function Tag({ label, size = "SM", theme = "neutral", subtle = false }: { label: string, size?: TagSize, theme?: TagTheme, subtle?: boolean }) {
  return (
    <div className={ cx(
      "tag",
      tagSizes[size],
      tagThemes[theme],
      subtle && "tag-subtle"
    ) }>
      { label }
    </div>
  );
};
