import { MouseEventHandler, ReactNode } from "react";

import { cx } from "utils";


export default function ExtLink({
  className,
  href,
  id,
  onClick,
  disabled,
  children
}: {
  className?: string;
  href: string;
  id?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean
  children: ReactNode;
}) {
  if (disabled) {
    return <span className={cx(className)}>
      {children}
    </span>
  }
  return (
    <a
      className={cx(className)}
      target="_blank"
      id={id}
      rel="noreferrer"
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  );
}
