import { isValidAsin } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const AmazonProductContent: SDEContent = {
  projectType: 'async_amazon_product',
  projectCategory: 'amazon',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/amazon/product`;
  },
  inputKey: 'asin',
  inputsKey: 'asins',
  validatorFunction: isValidAsin,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'ASINs',
      listLiteralInputPlaceholder: 'Enter one ASIN per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: 'Input ASINs',
      inputSectionDescription: "Based on the data type you selected, you need to add ASINs to begin scraping Amazon.",
    },
    projectContent: {
      title: 'Amazon product pages',
      description: 'Extract product details from Amazon product pages with ASINs such as product name, price and ratings. Download the results in a well-structured JSON file.',
      icon: 'amazon',
      inputTitle: 'Asin',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input ASINs',
      inputSectionDescription: 'Enter Amazon ASINs to begin scraping Amazon.',
    },
    projectContent: {
      title: 'Amazon product pages',
      description: 'Extract product details from Amazon product pages with ASINs such as product name, price and ratings.',
      icon: 'amazon',
    },
    enterInputsPlaceholder: 'B06X3WKY59',
    enterInputsMessage: 'Enter ASIN for scraping',
    codeViewPlaceHolder: 'Please provide a valid ASIN',
    dropdownTitle: 'Amazon Product'
  }
};
