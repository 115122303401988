import { isValidQuery } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const GoogleJobsContent: SDEContent = {
  projectType: 'async_google_jobs',
  projectCategory: 'google',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/google/jobs`;
  },
  inputKey: 'query',
  inputsKey: 'queries',
  validatorFunction: isValidQuery,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Search terms',
      listLiteralInputPlaceholder: 'Enter one search term per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input search term",
      inputSectionDescription: "Enter search terms in the input field or upload CSV with your search terms to begin scraping Google.",
    },
    projectContent: {
      title: 'Google jobs',
      description: 'Extract data from Google job results and download the data as a well-structured JSON file.',
      icon: 'google',
      inputTitle: 'Query',
    },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input search term',
      inputSectionDescription: 'Enter Google search terms or full URLs to begin scraping Google.',
    },
    projectContent: {
      title: 'Google jobs',
      description: 'Extract data from Google job results.',
      icon: 'google',
    },
    enterInputsPlaceholder: 'query',
    enterInputsMessage: 'Enter keyword for scraping',
    codeViewPlaceHolder: 'Please provide a valid search term',
    dropdownTitle: 'Google Jobs'
  }
};
