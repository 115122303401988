import ReactCodeMirror from "@uiw/react-codemirror";
import { SupportedLanguages } from "routes/dashboard/ApiPlaygroundTypes";

import {java} from "@codemirror/lang-java";
import {javascript} from "@codemirror/lang-javascript";
import {php} from "@codemirror/lang-php";
import {html} from "@codemirror/lang-html";

import {basicSetup} from "codemirror"
import {cobalt} from "thememirror"

// At the time of writing codemirror 6 has no ruby and shell script support so I
// used legacy mode from codemirror 5
// 
// https://github.com/codemirror/legacy-modes

import {StreamLanguage} from "@codemirror/language";
import {shell} from "@codemirror/legacy-modes/mode/shell";
import {python} from "@codemirror/legacy-modes/mode/python";
import {ruby} from "@codemirror/legacy-modes/mode/ruby";

export interface CodeViewProps {
  language: SupportedLanguages | 'json' | 'html';
  content: string | undefined;
  placeholder: string | undefined;
}

export const CodeView = ({language, content, placeholder}: CodeViewProps) => {
  const languageExtension = (() => {
    switch (language) {
      case 'curl': return StreamLanguage.define(shell);
      case 'python': return StreamLanguage.define(python);
      case 'nodejs': return javascript(); 
      case 'php': return php();
      case 'ruby': return StreamLanguage.define(ruby);
      case 'java': return java();
      //-----
      case 'json': return javascript();
      case 'html': return html();
    }
  })();
  const extensions = [basicSetup, languageExtension];

  return (
  <div className="scroll">
    <ReactCodeMirror 
      value={content}
      readOnly={true}
      extensions={extensions}
      // theme="dark"
      // maxWidth="100rem"
      theme={cobalt}
      minHeight="10rem"
      placeholder={placeholder}
    />
  </div>
  )
}