import React, { ReactNode } from 'react';
import { cx } from 'utils';

import { Size, Sizes } from 'styles/sizes';

type CardSizes = Sizes<{ padding: string, gap: string }>;

const cardSizes: CardSizes = {
  SM: { padding: "p-3", gap: "gap-y-3" },
  MD: { padding: "p-4", gap: "gap-y-4" },
  LG: { padding: "p-5", gap: "gap-y-5" },
  XL: { padding: "p-6", gap: "gap-y-6" },
};

interface CardProps {
  title?: string | ReactNode;
  extra?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  className?: string;
  size?: Size;
  hoverable?: boolean;
  segmented?: boolean;
  borderless?: boolean;
}

const Card: React.FC<CardProps> = ({
  title,
  extra,
  children,
  footer,
  className,
  size = "MD",
  hoverable = false,
  segmented = false,
  borderless = false,
}) => {
  const sizeClass = cardSizes[size];

  return (
    <div
      className={cx(
        !borderless && "dark:rounded-md border border-neutral-200",
        hoverable && "transition-colors duration-200 dark:hover:bg-accent-50/50",
        "flex flex-col justify-between",
        !borderless && sizeClass?.padding,
        sizeClass?.gap,
        className
      )}
    >
      {(title || extra) && (
        <div className={cx(
          "flex justify-between items-center",
          segmented && "border-b border-neutral-100"
        )}>
          {title && <h2 className="text-lg font-medium">{title}</h2>}
          {extra && <div>{extra}</div>}
        </div>
      )}
      {children}
      {footer && (
        <div>{footer}</div>
      )}
    </div>
  );
};

export default Card;
